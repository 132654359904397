import { Outlet, useNavigate } from 'react-router-dom'
import Header from '@src/components/Header/Header'
import Layout from '@src/components/Layout/Layout'
import BackBtn from '@src/components/BackBtn/BackBtn'
import { path } from '@src/config'
import './ClientAuthLayout.scss'

const ClientAuthLayout = () => {
	const navigate = useNavigate()

	const goHome = () => {
		navigate(path.HOME)
	}

	return (
		<Layout id="ClientAuthLayout" className="auth-form">
			<div className="auth-form__actions">
				<BackBtn
					title="На главную"
					text="На главную"
					callback={goHome}
				/>
			</div>
			<Header light={true} />
			<Outlet />
		</Layout>
	)
}

export default ClientAuthLayout