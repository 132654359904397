const enterFullScreen = () => {
	if (!document.fullscreenElement) {
		document.documentElement.requestFullscreen().catch((err) => {
			console.error(`Не удалось перейти в полноэкранный режим: ${err.message}`)
		})
	} else {
		console.log("Документ уже находится в полноэкранном режиме.")
	}
}

export default enterFullScreen