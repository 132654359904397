import { useState } from 'react'
import NailsSelector from '../NailsSelector/NailsSelector'
import ActionBtn from './ActionBtn'
import './StudioConstructor.scss'

const StudioConstructor = ({
	modeIsSelected,
	setModeIsSelected,
	onNailSelect,
	disabled
}) => {

	const [mode, setMode] = useState('')

	const selecteMode = (value) => {
		setMode(value)
		setModeIsSelected(true)
	}

	return (
		<div className="StudioConstructor">
			{modeIsSelected ? (
				<NailsSelector {...{ mode, onNailSelect, disabled}} />
			) : (
				<div className="StudioConstructor_btns">
					<ActionBtn
						text="Цвет"
						onClick={() => selecteMode('color')}
					/>
					<ActionBtn
						text="Дизайн"
						onClick={() => selecteMode('design')}
						disabled
					/>
					<ActionBtn
						text="Длина"
						onClick={() => selecteMode('length')}
						disabled
					/>
					<ActionBtn
						text="Форма"
						onClick={() => selecteMode('form')}
						disabled
					/>
				</div>
			)}
		</div>
	)
}

export default StudioConstructor
