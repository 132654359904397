import './PhotoBtn.scss'

const PhotoBtn = ({hold, ...props}) => {
	return (
		<button
			className="PhotoBtn"
			disabled={hold}
			{...props}
		/>
	)
}

export default PhotoBtn