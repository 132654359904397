import { getUserToken } from '@src/services/auth/getUserToken'
import { request } from '@src/utility/request'
import { api } from '@src/config'
import { getAPI } from '@src/utility/getAPI'
import { POST } from '@src/helpers/constants'

export const rateMaster = async ({score, client, master}) => {

	const userToken = getUserToken()

	try {
		const {response, status} = await request({
			url: getAPI([api.rateMaster]),
			token: userToken,
			message: {
				score,
				client,
				master
			},
			method: POST,
		})

		console.debug({response})
		console.debug({status})

		if (response?.master === master && response?.score)
			return { success: true, status, ...{response} }
		else
			return { success: false, status, ...{response} }

	} catch (error) {
		console.error('Error sending request:', error)
	}

}
