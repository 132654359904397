import getRGB from "./getRGB"

const getNailColor = (mainColor, glowColor) => {
	return (
		{
			backgroundColor: getRGB(mainColor),
			backgroundImage: `radial-gradient(ellipse, ${getRGB(glowColor)}, rgba(255, 255, 255, 0) 70%)`

		}
	)
}

export default getNailColor