import './Loader.scss'

const Loader = ({text}) => {
	return (
		<div className="Loader">
			<div>
				{text}
			</div>
		</div>
	)
}

export default Loader