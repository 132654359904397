const exitFullScreen = () => {
	if (document.fullscreenElement) {
		document.exitFullscreen().catch((err) => {
			console.error(`Не удалось выйти из полноэкранного режима: ${err.message}`)
		})
	} else {
		console.log('Документ уже не находится в полноэкранном режиме.')
	}
}

export default exitFullScreen