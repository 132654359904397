import BackBtn from '../BackBtn/BackBtn'
// import Btn from '../Btn/Btn'
import './StudioControll.scss'

const StudioControll = ({ backLinkAction, backLinkText, children }) => {
	return (
		<div className="StudioControll">
			<div className="container">
				<BackBtn title="Назад" callback={backLinkAction} text={backLinkText}/>
				{children}
			</div>
		</div>
	)
}

export default StudioControll