import { GlobalAppConfig } from "@src/config"

export const startVideo = (
	videoRef,
	studioCameraRef,
	setIsVideoStarted,
	setSpaceSize
) => {
	return new Promise((resolve, reject) => {
		if (!videoRef.current) {
			reject('Видео элемент не найден')
			return
		}

		// const mainWidth = studioCameraRef?.current?.clientWidth
		// const mainHeight = studioCameraRef?.current?.clientHeight
		// const mainWidth = '220px'
		// const mainHeight ='220px'

		// const newWidth = {
		// 	min: mainWidth,
		// 	max: mainWidth * 1.5
		// }
		// const newHeight = {
		// 	min: mainHeight,
		// 	max: mainHeight * 1.5
		// }

		const facingMode = GlobalAppConfig?.facingMode || 'environment'

		// console.log(`newWidth: ${newWidth?.min}`)
		// console.log(`newHeight: ${newHeight?.min}`)

		navigator.mediaDevices.getUserMedia({
			video: {
				// width: { ideal: 3840 },
				// height: { ideal: 2160 },
				// width: { ideal: 4160 },
				// height: { ideal: 3120 },
				width: { ideal: 2840 },
				height: { ideal: 2118 },
				facingMode: facingMode,
			},
		})
			.then((stream) => {
				videoRef.current.srcObject = stream
				videoRef.current.play()
				setIsVideoStarted(true)

				const videoTrack = stream.getVideoTracks()[0];
				const settings = videoTrack.getSettings();
				// TODO just you to know, here actual size of video from camera and its aspect ratio
				const { width, height, aspectRatio } = settings;
				const isHorizontalVideoStream = aspectRatio < 1;
				console.log({width, height, isHorizontalVideoStream});

				const stopVideo = () => {
					stream.getTracks().forEach((track) => track.stop())
					videoRef.current.srcObject = null
					setIsVideoStarted(false)
				}
				// TODO changed
				setSpaceSize({
					width,
					height,
					isHorizontalVideoStream
				})

				resolve(stopVideo)
			})
			.catch((err) => {
				console.error('Error accessing webcam:', err)
				alert('Не удалось получить доступ к камере. Проверьте разрешения.')
				setIsVideoStarted(false)
				reject(err)
			})
	})
}
