import { useEffect } from 'react'
import './StudioCamera.scss'

const StudioCamera = ({
	studioCameraRef,
	videoRef,
	canvasRef,
	serverImageRef,
	mode,
	blured
}) => {
	useEffect(() => {
		if (mode === 'canvasMode') {
			const canvasWrapper = studioCameraRef.current
			if (canvasWrapper) {
				canvasWrapper.classList.add('animate')

				setTimeout(() => {
					canvasWrapper.classList.remove('animate')
				}, 750)
			}
		}
	}, [mode, studioCameraRef])

	const cssClasses = ['StudioCamera']

	cssClasses.push((`StudioCamera_${mode}`))
	
	if (blured)
		cssClasses.push((`StudioCamera_blured`))

	return (
		<div
			className={cssClasses.join(' ')}
			ref={studioCameraRef}
		>
			<video
				ref={videoRef}
				autoPlay
				playsInline
			/>
			<canvas ref={canvasRef} />
			<img
				id="server-image"
				ref={serverImageRef}
				alt=""
			/>
		</div>
	)
}

export default StudioCamera
