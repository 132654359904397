import { api, GlobalAppConfig } from '@src/config'
import { getBlobImageFromCanvas } from './camera'
import { getAPI } from './getAPI'
import { resizeImageIfNecessary } from './resizeImageIfNecessary'
import { createImageFromBlob } from './createImageFromBlob'

export const saveImage = async (
	canvasRef,
	userToken,
	clientId,
	callback
) => {
	const maxWidth = GlobalAppConfig.maxSentImageWidth || 1280

	try {
		const blob = await getBlobImageFromCanvas(canvasRef.current)
		const image = await createImageFromBlob(blob)

		const resizedBlob = await resizeImageIfNecessary(image, maxWidth)
	
		const formData = new FormData()
		formData.append('photo', resizedBlob, 'image.png')

		const response = await fetch(
			getAPI([api.clients, clientId, api.designs]),
			{
				method: 'POST',
				headers: {
					Authorization: `Token ${userToken}`,
				},
				body: formData,
			}
		)

		if (!response.ok) throw new Error(`Ошибка загрузки изображения: ${response.statusText}`)

		console.log('Изображение успешно сохранено!')
		callback(true)
	} catch (error) {
		console.error('Error sending image to server:', error)
		alert('Ошибка при обработке изображения.')
		callback(false)
	}
}
