import getContrastingColor from '@src/utility/getContrastingColor'
import getNailColor from '@src/utility/getNailColor'
import getRGB from '@src/utility/getRGB'
import { defaultNailGlowRGBA, defaultNailRGBA } from '@src/config'
import './Nail.scss'

const Nail = ({
	nailId,
	mainColor = defaultNailRGBA,
	glowColor = defaultNailGlowRGBA,
	code,
	name,
	selectedId,
	setSelectedId,
	onClick,
	disabled
}) => {

	const handleClick = () => {
		if (disabled) return
		setSelectedId(nailId)
		if (onClick) {
			onClick()
		}
	}

	let cssClasses = ['Nail']

	if (selectedId === nailId)
		cssClasses.push('Nail_active')

	if (disabled)
		cssClasses.push('Nail_disabled')

	return (
		<div
			className={cssClasses.join(' ')}
			onClick={handleClick}
		>
			<div
				className="Nail__preview"
				style={getNailColor(mainColor, glowColor)}
			></div>
			<div
				className="Nail__code"
				style={{
					backgroundColor: getRGB(mainColor),
					color: getContrastingColor(mainColor),
				}}
			>
				{code}
			</div>
			{name &&
				<div className="Nail__name">{name}</div>
			}
		</div>
	)
}

export default Nail
