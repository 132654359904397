import './Overlay.scss'

const Overlay = ({children}) => {
	return (
		<div className="Overlay">
			{children}
		</div>
	)
}

export default Overlay