import { Link } from 'react-router-dom'
import './MegaListItem.scss'

const MegaListItem = ({href, to, src, text}) => {

	if (to)
		return (
			<Link className="MegaListItem" {...{to}}>
				<img {...{src}} alt={text} />
				{text}
			</Link>
		)
	else
		return (
			<a
				className="MegaListItem"
				target="_blank"
				{...{href}}
			>
				<img {...{src}} alt={text} />
				<span>{text}</span>
			</a>
		)
}

export default MegaListItem