import { getClientData } from '@src/utility/getClientData'
import FetchGallery from "@src/components/FetchGallery/FetchGallery"
import { getAPI } from '@src/utility/getAPI'
import { api } from '@src/config'

const FeaturedWorks = () => {
	const {id: clientId} = getClientData()

	return (
		<div className="FeaturedWorks">
			<code>WIP, clients-designs placholder:</code>
			<FetchGallery
				url={getAPI([api.clients, clientId, api.designs])}
				pageName="FeaturedWorks"
				initialParams={{ clientId }}
			/>
		</div>
	)
}

export default FeaturedWorks