import useTitle from '@src/hooks/useTitle.js'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'
import MegaList from '@src/modules/MegaList/MegaList'
// import MegaListItem from '@src/components/MegaListItem/MegaListItem'

const Magazines = () => {
	const title = useTitle('Журналы')

	return (
		<div>
			<MegaHeader design='material' {...{title}} />
			<div className="container">
				<MegaList>
					<p>Тут пока пусто…</p>
					{/* <MegaListItem
						href={'https://yandex.ru/games/app/208909'}
						src={'/images/external/games/поиск-отличий.png'}
						text={'1111'}
					/> */}
				</MegaList>
			</div>
		</div>
	)
}

export default Magazines
