import { useRef, useState, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserToken } from '@src/services/auth/getUserToken'
import { defaultRGB } from '@src/helpers/vars'
// import { useWebSocket } from '@src/hooks/useWebSocket'
import { captureImageFromVideo } from '@src/utility/camera'
import { startVideo } from '@src/utility/startVideo'
import { sendImageToServer } from '@src/utility/sendImageToServer'
import Btn from '@src/components/Btn/Btn'
import PhotoBtn from '@src/components/PhotoBtn/PhotoBtn'
import Overlay from '@src/modules/Overlay/Overlay'
import StudioControll from '@src/components/Studio/StudioControll'
import StudioCamera from '@src/components/Studio/StudioCamera/StudioCamera'
import StudioConstructor from '@src/components/Studio/StudioConstructor'
import Loader from '@src/components/Loader/Loader'
import { getClientData } from '@src/utility/getClientData'
import { saveImage } from '@src/utility/saveImage'
import './Studio.scss'
import { getAPI } from '@src/utility/getAPI'
import { api, GlobalAppConfig } from '@src/config'
import exitFullScreen from '@src/utility/exitFullScreen'
import enterFullScreen from '@src/utility/enterFuulScreen'

const Studio = () => {
	const studioCameraRef = useRef(null)
	const videoRef = useRef(null)
	const canvasRef = useRef(null)
	const serverImageRef = useRef(null)
	const cameraMode = useRef('videoMode')

	const [isLoading, setIsLoading] = useState(false)
	const [isSaved, setIsSaved] = useState(false)
	const [isWaitingResult, setIsWaitingResult] = useState(false)
	const [resultTimeout, setResultTimeout] = useState(false)
	const [isPhotoMode, setIsPhotoMode] = useState(true)
	const [isVideoStarted, setIsVideoStarted] = useState(false)
	const [modeIsSelected, setModeIsSelected] = useState(false)
	const [taskId, setTaskId] = useState(null)
	const [spaceSize, setSpaceSize] = useState(null)
	const [isTakenPhoto, setIsTakenPhoto] = useState(false)

	const [stopVideoFunction, setStopVideoFunction] = useState(null)

	const navigate = useNavigate()

	const userToken = useMemo(() => getUserToken(), [])

	// const handleWebSocketMessage = useCallback((event) => {
	// 	console.debug({ event })
	// 	if (!serverImageRef?.current) return

	// 	const base64Image = event.data
	// 	serverImageRef.current.src = base64Image
	// 	setIsWaitingResult(false)
	// 	setResultTimeout(false)
	// 	cameraMode.current = 'imgMode'
	// }, [])

	// const { isConnected } = useWebSocket(
	// 	studioWebSocketUrl,
	// 	userToken,
	// 	handleWebSocketMessage
	// )

	useEffect(() => {
		console.log({spaceSize});
		console.log({videoRef});

		if (!spaceSize || !videoRef) return

		if (spaceSize?.isHorizontalVideoStream)
			videoRef.current.style.width = '100%'
		else
			videoRef.current.style.height = '100%'

	}, [spaceSize])
	

	const checkTaskResult = useCallback(
		async (taskId) => {
			if (!taskId) return

			console.log('checkTaskResult', taskId)

			try {
				const response = await fetch(getAPI([api.imageProcessTask, taskId]), {
					method: 'GET',
					headers: {
						Authorization: `Token ${userToken}`,
						'Content-Type': 'application/json',
					},
				})

				let data
				try {
					data = await response.json()
				} catch (e) {
					throw new Error('Ошибка парсинга ответа от сервера')
				}

				if (response.status === 200) {
					if (data.result) {
						if (resultTimeout) {
							setResultTimeout(false)
						}
						if (serverImageRef?.current) {
							serverImageRef.current.src = 'data:image/png;base64,' + data.result
							setIsWaitingResult(false)
							setResultTimeout(false)
							cameraMode.current = 'imgMode'
							setTaskId(null)
						}
					} else {
						throw new Error('Неизвестный ответ от сервера')
					}
				} else if (response.status === 404) {
					if (data.status === 'not_found') {
						setTimeout(() => {
							if (isWaitingResult) {
								checkTaskResult(taskId)
							}
						}, GlobalAppConfig?.resultRepeatRequest || 2000)
					} else {
						throw new Error(`Ошибка получения результата: ${data.error || response.statusText}`)
					}
				} else {
					throw new Error(`Ошибка получения результата: ${data.error || response.statusText}`)
				}
			} catch (error) {
				console.error('Ошибка при получении результата:', error)
				alert('Ошибка при обработке изображения.')
				setIsWaitingResult(false)
				setResultTimeout(false)
				setTaskId(null)
			}
		},
		[userToken, isWaitingResult, resultTimeout]
	)

	const handleStartVideo = useCallback(async () => {
		enterFullScreen()

		try {
			const stopVideo = await startVideo(
				videoRef,
				studioCameraRef,
				setIsVideoStarted,
				setSpaceSize
			)
			setStopVideoFunction(() => stopVideo)
	} catch (err) {
			console.error('Ошибка при запуске видео:', err)
		}
	}, [])

	const handleStopVideo = useCallback(() => {
		if (stopVideoFunction) {
			stopVideoFunction()
			setStopVideoFunction(null)
		}
	}, [stopVideoFunction])

	const captureImage = useCallback(async () => {
		setIsTakenPhoto(true)
		try {
			await captureImageFromVideo(
				videoRef.current,
				canvasRef.current,
				spaceSize
			)
			setIsPhotoMode(false)
			setIsTakenPhoto(false)
			cameraMode.current = 'canvasMode'
		} catch (error) {
			console.error('Error capturing image:', error)
		}
	}, [spaceSize])

	const handleNailSelect = useCallback((nail) => {
		console.log('Selected Nail:', nail)
		setIsWaitingResult(true)
		sendImageToServer(
			canvasRef,
			userToken,
			nail?.main || defaultRGB,
			setIsLoading,
			setTaskId
		)
	}, [userToken])

	const backLinkAction = () => {
		if (modeIsSelected) {
			setModeIsSelected(false)
			setIsPhotoMode(false)
		} else if (!isPhotoMode && !modeIsSelected) {
			setIsPhotoMode(true)
			setIsSaved(false)
			cameraMode.current = 'videoMode'
		} else {
			handleStopVideo()
			exitFullScreen()
			navigate(-1)
		}
	}

	const getBackLinkText = () => {
		if (modeIsSelected) {
			return 'К категориям'
		} else if (!isPhotoMode && !modeIsSelected) {
			return 'Новое фото'
		} else {
			return 'На главную'
		}
	}

	const onImageSaved = (isSuccess) => {
		setIsLoading(false)
		if (isSuccess)
			setIsSaved(true)
	}

	const saveHandleImage = () => {
		const clientData = getClientData()

		if (!clientData || !clientData?.id) {
			alert('Для сохранения необходима авторизация клиента')
			return
		}

		const clientId = clientData?.id
		console.log({clientData})

		saveImage(
			canvasRef,
			userToken,
			clientId,
			onImageSaved
		)

	}

	const cancelWaiting = () => {
		setResultTimeout(false)
		setIsWaitingResult(false)
		setTaskId(null)
	}

	useEffect(() => {
		let timer

		if (isWaitingResult) {
			setResultTimeout(false)

			timer = setTimeout(() => {
				setResultTimeout(true)
				setIsWaitingResult(false)
				setTaskId(null)
			}, GlobalAppConfig?.resultWaitingMaxTime || 8000)
		}

		return () => {
			clearTimeout(timer)
		}
	}, [isWaitingResult])

	useEffect(() => {
		let initialTimer

		if (taskId && isWaitingResult) {
			initialTimer = setTimeout(() => {
				checkTaskResult(taskId)
			}, GlobalAppConfig?.resultRequestTime || 3500)
		}

		return () => {
			clearTimeout(initialTimer)
		}
	}, [taskId, isWaitingResult, checkTaskResult])

	return (
		<div className="Studio">
			<StudioControll
				backLinkText={getBackLinkText()}
				{...{
					backLinkAction,
					isPhotoMode,
					setIsPhotoMode,
				}}
			>
				{cameraMode?.current === 'canvasMode' &&
					<Btn
						label={isSaved ? 'Сохранено' : 'Сохранить результат'}
						type="light"
						onClick={saveHandleImage}
						disabled={isSaved || isWaitingResult}
					/>
				}
			</StudioControll>

			{!isVideoStarted &&
				<Overlay>
					<Btn
						type="light"
						label="Включить камеру"
						onClick={handleStartVideo}
					/>
				</Overlay>
			}

			{/* <Loader text={testSize} /> */}
			{resultTimeout ? (
				<Overlay>
					<div style={{textAlign: 'center'}}>
						<p>Время ожидания ответа превышено</p>
						<br />
						<Btn
							type="glass"
							label="Закрыть"
							onClick={cancelWaiting}
						/>
					</div>
				</Overlay>
			// ) : !isConnected ? (
			// 	<Loader text="Соединение с сервером…" />
			) : isLoading ? (
				<Loader text="Загрузка фотографии…" />
			) : isWaitingResult ? (
				<Loader text="Обработка фотографии…" />
			) : ''}

			<StudioCamera
				mode={cameraMode.current}
				blured={isWaitingResult || isTakenPhoto}
				{...{
					studioCameraRef,
					videoRef,
					canvasRef,
					serverImageRef
				}}
			/>
			<div className="Studio__panel">
				{isPhotoMode ? (
					<PhotoBtn
						onClick={captureImage}
						hold={isTakenPhoto}
					/>
				) : (
					<StudioConstructor
						onNailSelect={handleNailSelect}
						disabled={isWaitingResult}
						{...{
							modeIsSelected,
							setModeIsSelected,
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default Studio
