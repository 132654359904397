import { useEffect, useState } from 'react'
import Gallery from '@src/components/Gallery/Gallery'
import { IS_LOADED } from '@src/helpers/constants'
import Message from '@src/components/Message/Message'
import Paginate from '@src/components/Paginate/Paginate'
import { galleryPageSize } from '@src/config'
import { useDispatch } from 'react-redux'
import { setFilters } from '@src/store/reducers/filtersSlice'
import { updatePageParams } from '@src/utility/updatePageParams'
import PaginateNote from '../PaginateNote/PaginateNote'
import useFetchData from '@src/hooks/useFetchData'
import { text } from '@src/helpers/text'

const FetchGallery = ({
	url,
	pageName,
	initialParams,
	setCount
}) => {

	const dispatch = useDispatch()

	const [params, setParams] = useState({
		page: 1,
		...initialParams
	})

	const { fetchData, status } = useFetchData(url,params)

	const handlePageClick = ({ selected }) => {
		if (selected >= 0) {
			setParams(updatePageParams(params, selected + 1))
		} else {
			console.warn('Pagination error', { selected })
		}
	}

	useEffect(() => {
		dispatch(setFilters({
			page: pageName,
			filters: params
		}))
	}, [params, dispatch, pageName])

	useEffect(() => {
		if (setCount) {
			setCount(fetchData?.count || 0)
		}
	}, [fetchData, setCount])

	return (
		<div className="FetchGallery">
			{status === IS_LOADED ?
				<>
					<Gallery images={fetchData?.results} />
					<PaginateNote
						text={`Показано: ${fetchData?.results?.length || text.notSetCompact} из ${fetchData?.count}`}
					/>
					<Paginate
						onPageChange={handlePageClick}
						pageRangeDisplayed={galleryPageSize}
						count={fetchData?.count || 1}
						page={params?.page}
					/>
				</>
				:
				<Message status={status} />
			}
		</div>
	)
}

export default FetchGallery
