import useTitle from '@src/hooks/useTitle.js'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'
import MegaList from '@src/modules/MegaList/MegaList'
import MegaListItem from '@src/components/MegaListItem/MegaListItem'

const Games = () => {
	const title = useTitle('Игры')

	return (
		<div>
			<MegaHeader design="games-banner" {...{title}} />
			<div className="container">
				<MegaList>
					<MegaListItem
						href={'https://yandex.ru/games/app/200593'}
						src={'/images/external/games/bubble-fall.png'}
						text={'Bubble Fall'}
					/>
					<MegaListItem
						href={'https://yandex.ru/games/app/208909'}
						src={'/images/external/games/поиск-отличий.png'}
						text={'Поиск Отличий'}
					/>
					<MegaListItem
						href={'https://yandex.ru/games/app/96786'}
						src={'/images/external/games/скайдом-три-в-ряд.png'}
						text={'Скайдом - Три в Ряд!'}
					/>
				</MegaList>
			</div>
		</div>
	)
}

export default Games
