import { convertBlobToBase64 } from '@src/utility/convertBlobToBase64'
import { api, GlobalAppConfig } from '@src/config'
import { getBlobImageFromCanvas } from '@src/utility/camera'
import { getAPI } from '@src/utility/getAPI'
import { createImageFromBlob } from './createImageFromBlob'
import { resizeImageIfNecessary } from './resizeImageIfNecessary'

export const sendImageToServer = async (
	canvasRef,
	userToken,
	rgb,
	setIsLoading,
	setTaskId
) => {
	setIsLoading(true)
	const maxWidth = GlobalAppConfig.maxSentImageWidth || 1280
	
	try {
		const blob = await getBlobImageFromCanvas(canvasRef.current)
		const image = await createImageFromBlob(blob)
		
		// ? TODO here we potentially losing quality, cause modern cameras has resolution x2/x4 from 1280
		const resizedBlob = await resizeImageIfNecessary(image, maxWidth)

		const base64Image = await convertBlobToBase64(resizedBlob)

		const response = await fetch(getAPI([api.imageProcessTask]), {
			method: 'POST',
			headers: {
				Authorization: `Token ${userToken}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ image: base64Image, color: rgb }),
		})

		if (!response.ok)
			throw new Error(`Ошибка загрузки изображения: ${response.statusText}`)

		const data = await response.json()

		if (data.task_id) {
			setTaskId(data.task_id)
		} else {
			throw new Error('task_id не получен от сервера')
		}
	} catch (error) {
		console.error('Error sending image to server:', error)
		alert('Ошибка при обработке изображения.')
	} finally {
		setIsLoading(false)
	}
}
