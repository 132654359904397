import { GlobalAppConfig } from '@src/config'

export const resizeImageIfNecessary = (image, maxWidth) => {
	return new Promise((resolve) => {
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')

		let width = image.width
		let height = image.height

		console.log('Old image width:', {width}, {height});
		// Измените размеры, если ширина превышает maxWidth
		if (width > maxWidth) {
			height *= (maxWidth / width)
			width = maxWidth
		}

		canvas.width = width
		canvas.height = height

		console.log('New image width:', {width}, {height});

		ctx.drawImage(image, 0, 0, width, height)

		canvas.toBlob((blob) => {
			resolve(blob)
		}, GlobalAppConfig.sentImageFormat, GlobalAppConfig.sentImageQuality)
	})
}