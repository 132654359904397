import React, { useRef, useState, useEffect } from 'react'

const CameraComponent = () => {
	const videoRef = useRef(null)
	const canvasRef = useRef(null)
	const [capturedImage, setCapturedImage] = useState(null)
	const [isPlaying, setIsPlaying] = useState(false)

	useEffect(() => {
		let isMounted = true

		const getCamera = async () => {
			try {
				const stream = await navigator.mediaDevices.getUserMedia({
					video: {
						facingMode: 'environment',
						width: { exact: 1024 },
						height: { exact: 1024 },
					},
					audio: false,
				})
				if (isMounted && videoRef.current) {
					videoRef.current.srcObject = stream
					videoRef.current.onloadedmetadata = () => {
						videoRef.current.play()
							.then(() => setIsPlaying(true))
							.catch(error => {
								console.error("Ошибка при воспроизведении видео:", error)
								alert("Не удалось воспроизвести видео. Попробуйте обновить страницу или проверить разрешения.")
							})
					}
				}
			} catch (err) {
				console.error("Ошибка доступа к камере:", err)
				alert("Не удалось получить доступ к камере. Проверьте разрешения и подключение.")
			}
		}

		getCamera()

		return () => {
			isMounted = false
			if (videoRef.current && videoRef.current.srcObject) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				videoRef.current.srcObject.getTracks().forEach(track => track.stop())
			}
		}
	}, [])

	const takePicture = () => {
		const video = videoRef.current
		const canvas = canvasRef.current
		if (video && canvas) {
			const width = video.videoWidth
			const height = video.videoHeight

			canvas.width = width
			canvas.height = height

			const ctx = canvas.getContext('2d')
			ctx.drawImage(video, 0, 0, width, height)

			const dataURL = canvas.toDataURL('image/png')
			setCapturedImage(dataURL)
		}
	}

	return (
		<div style={{ 
			display: 'flex', 
			flexDirection: 'column', 
			alignItems: 'center', 
			height: '100vh', 
			width: '100vw',
			boxSizing: 'border-box',
			background: 'steelblue',
			// padding: '10px',
		}}>
			<h2>Демонстрация камеры</h2>
			<div style={{ 
				position: 'relative', 
				width: '100%', 
				flexGrow: 1, 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center',
				backgroundColor: '#000',
			}}>
				<video
					ref={videoRef}
					style={{ 
						// width: '100%', 
						// height: '100%', 
						// objectFit: 'cover',
						borderRadius: '8px',
						// transform: 'scale(.5)'
					}}
					playsInline
				/>
			</div>
			<button
				onClick={takePicture}
				style={{ 
					marginTop: '10px', 
					padding: '10px 20px', 
					fontSize: '16px',
					borderRadius: '5px',
					cursor: 'pointer',
				}}
				disabled={!isPlaying}
			>
				Сделать снимок
			</button>
			<canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
			{capturedImage && (
				<div style={{ 
					marginTop: '20px', 
					width: '100%', 
					flexGrow: 1, 
					display: 'flex', 
					justifyContent: 'center', 
					alignItems: 'center',
				}}>
					<img
						src={capturedImage}
						alt="Captured"
						style={{ 
							width: '100%', 
							height: '100%', 
							objectFit: 'cover',
							borderRadius: '8px',
						}}
					/>
				</div>
			)}
		</div>
	)
}

export default CameraComponent
