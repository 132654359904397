import { getAPI } from '@src/utility/getAPI'
import FetchGallery from '../FetchGallery/FetchGallery'
import { api } from '@src/config'

const PortfolioGallery = ({ masterId, setPortfolioCount }) => {
	return (
		<div className="PortfolioGallery">
			<FetchGallery
				url={getAPI([api.masters, masterId, api.designs])}
				pageName="PortfolioGallery"
				initialParams={{ masterId }}
				setCount={setPortfolioCount}
			/>
		</div>
	)
}

export default PortfolioGallery
