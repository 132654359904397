export const captureImageFromVideo = async (
	videoElement,
	canvasElement,
	spaceSize
) => {
	return new Promise(async (resolve, reject) => {
		if (!videoElement || !canvasElement || !spaceSize) {
			reject('Invalid parameters')
			return
		}

		const stream = videoElement.srcObject
		if (!stream) {
			console.error('Stream not found.')
			reject('Stream not found')
			return
		}

		const track = stream.getVideoTracks()[0]
		if (!track) {
			console.error('Video track not found.')
			reject('Video track not found')
			return
		}

		try {
			// Проверяем, поддерживает ли устройство управление вспышкой (torch)
			const capabilities = track.getCapabilities()
			if (capabilities.torch) {
				// Включаем вспышку перед созданием фотографии
				await track.applyConstraints({ advanced: [{ torch: true }] })
				console.log('Вспышка включена перед съемкой')
			}

			// Пытаемся использовать ImageCapture для получения фото в максимальном разрешении
			const imageCapture = new ImageCapture(track)
			const photoBlob = await imageCapture.takePhoto()

			// Выключаем вспышку после съемки
			if (capabilities.torch) {
				await track.applyConstraints({ advanced: [{ torch: false }] })
				console.log('Вспышка выключена после съемки')
			}

			// Преобразуем Blob в Image и рисуем его на Canvas
			const img = new Image()
			img.src = URL.createObjectURL(photoBlob)

			img.onload = () => {
				canvasElement.width = img.width
				canvasElement.height = img.height

				const context = canvasElement.getContext('2d')
				context.drawImage(img, 0, 0, img.width, img.height)

				URL.revokeObjectURL(img.src)

				console.log('Photo captured at max resolution using ImageCapture')
				resolve() // Разрешаем Promise после того, как изображение выведено на Canvas
			}

			img.onerror = (error) => {
				console.error('Failed to load image', error)
				reject('Failed to load image')
			}
		} catch (error) {
			console.warn('ImageCapture API not supported or failed. Falling back to Canvas capture.', error)

			// Убедимся, что вспышка выключена, если произошла ошибка
			try {
				await track.applyConstraints({ advanced: [{ torch: false }] })
			} catch (e) {
				console.error('Не удалось выключить вспышку после ошибки:', e)
			}

			// Резервный метод - с помощью Canvas
			const { videoWidth, videoHeight } = videoElement

			if (!videoWidth || !videoHeight) {
				console.error('Video dimensions are not available.')
				alert('Ошибка воспроизведения видео')
				reject('Video dimensions are not available')
				return
			}

			const frameWidth = spaceSize.width
			const frameHeight = spaceSize.height

			canvasElement.width = frameWidth
			canvasElement.height = frameHeight

			const context = canvasElement.getContext('2d')
			context.drawImage(videoElement, 0, 0, frameWidth, frameHeight)

			console.log('Photo captured using Canvas fallback')
			resolve() // Разрешаем Promise после того, как резервное фото выведено на Canvas
		}
	})
}

export const getBlobImageFromCanvas = (canvas) => {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => resolve(blob), 'image/png')
	})
}

export async function createBlobFromVideo(video, cb) {
	const stream = video.srcObject
	if (!stream) return

	const track = stream.getVideoTracks()[0]

	try {
		const imageCapture = new ImageCapture(track)
		const blob = await imageCapture.takePhoto()
		cb(blob)
	} catch (error) {
		console.warn(
		'ImageCapture API not supported or failed. Falling back to Canvas capture.',
		error
		)
		captureWithCanvas(video, cb)
	}
}

function captureWithCanvas(video, cb) {
	const canvas = document.createElement('canvas')
	canvas.width = video.videoWidth
	canvas.height = video.videoHeight

	const context = canvas.getContext('2d')
	context.drawImage(video, 0, 0, canvas.width, canvas.height)

	canvas.toBlob(
		(blob) => {
		if (blob) cb(blob)
		},
		'image/png'
	)
}