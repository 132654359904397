import useTitle from '@src/hooks/useTitle.js'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'
import { path } from '@src/config'
import MegaList from '@src/modules/MegaList/MegaList'
import './Entertainment.scss'
import MegaListItem from '@src/components/MegaListItem/MegaListItem'

const Entertainment = () => {
	const title = useTitle('Развлечения')

	return (
		<div>
			<MegaHeader design="material" {...{title}} />
			<div className="container">
				<MegaList>
					<MegaListItem
						src={'images/games-icon.jpg'}
						to={path.GAMES}
						text="Игры"
					/>
					<MegaListItem
						src={'images/magazins.jpg'}
						to={path.MAGAZINES}
						text="Журналы"
					/>
					<MegaListItem
						src={'images/shops.jpg'}
						to={path.SHOPS}
						text="Магазины"
					/>
					<MegaListItem
						src={'images/videos.jpg'}
						to={path.VIDEOS}
						text="Видео"
					/>
				</MegaList>
			</div>
		</div>
	)
}

export default Entertainment
