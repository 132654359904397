import useTitle from '@src/hooks/useTitle.js'
import MegaHeader from '@src/components/MegaHeader/MegaHeader'
import MegaList from '@src/modules/MegaList/MegaList'
import MegaListItem from '@src/components/MegaListItem/MegaListItem'

const Videos = () => {
	const title = useTitle('Видео')

	return (
		<div>
			<MegaHeader design="videos" {...{title}} />
			<div className="container">
				<MegaList>
					<MegaListItem
						href={'https://vk.com/video'}
						src={'/images/external/videos/vk-video.png'}
						text={'VK Video'}
					/>
					<MegaListItem
						href={'https://rutube.ru/'}
						src={'/images/external/videos/rutube.png'}
						text={'RuTube'}
					/>
				</MegaList>
			</div>
		</div>
	)
}

export default Videos
