import useTitle from '@src/hooks/useTitle.js'
import { logoutUser } from '@src/services/auth/logoutUser'
import { reloadPage } from '@src/utility/reloadPage'
import MegaBtn from '@src/components/MegaBtn/MegaBtn'
import MegaGridModule from '@src/modules/MegaGridModule/MegaGridModule'
import SimplyLink from '@src/components/SimplyLink/SimplyLink'
import { path } from '@src/config'
import { clientIsLogged } from '@src/utility/auth/client/clientIsLogged'
import ClientInfoTile from '@src/components/ClientInfoTile/ClientInfoTile'
import { clearClientData } from '@src/utility/clearClientData'
import { useNavigate } from 'react-router-dom'
import './Home.scss'

const Home = () => {
	useTitle('Главная')
	const navigate = useNavigate()

	const goToClientPage = () => {
		navigate(path.CLIENT_PROFILE)
	}

	const logout = async () => {
		await logoutUser()
		reloadPage()
	}

	const logoutClient = () => {
		clearClientData()
		reloadPage()
	}

	const isClient = clientIsLogged()

	return (
		<div className="Home">
			<MegaGridModule>
				{isClient ?
				<>
					<ClientInfoTile onClick={goToClientPage} />
					<MegaBtn
						onClick={logoutClient}
						image="material"
						label="Выйти из личного кабинета"
					/>
				</>
				:
				<MegaBtn
					to={path.CLIENT_REGISTRATION}
					image="chair"
					label="Личный кабинет"
					size={2}
				/>
				}
				<MegaBtn
					to={path.CREATION}
					image="phone"
					label="Создать свой дизайн"
				/>
				<MegaBtn
					to={path.REFERENCES}
					image="fingers"
					label="Референсы"
				/>
				<MegaBtn
					to={path.MASTER}
					image="tools"
					label="Мой мастер"
				/>
				<MegaBtn
					to={path.ENTERTAINMENT}
					image="games"
					label="Развлечения"
				/>
			</MegaGridModule>
			<SimplyLink
				value={'Выйти'}
				onClick={logout}
			/>
		</div>
	)
}

export default Home
