import { useState } from 'react'
import Btn from '@src/components/Btn/Btn'
import Input from '@src/components/Input/Input'
import LightFormModule from '@src/modules/LightForm/LightFormModule'
import { Link, } from 'react-router-dom'
import { haveErrors } from '@src/utility/haveErrors'
import { loginUser } from '@src/services/auth/loginUser'
import { GlobalAppConfig, path } from '@src/config'
import { reloadPage } from '@src/utility/reloadPage'
import { text } from '@src/helpers/text'
import { updateFormData } from '@src/utility/updateFormData'
import { validateLoginData } from '@src/utility/validation/validateLoginData'
import './UserLoginForm.scss'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import useTitle from '@src/hooks/useTitle'

const UserLoginForm = () => {
	const { RATES } = path

	const [isSending, setIsSending] = useState(false)
	const [errors, setErrors] = useState({})

	const title = useTitle('Авторизация салона')

	const writeErrors = (value) => {
		setErrors(init => ({...init, ...value}))
	}

	const [formData, setFormData] = useState({
		email: GlobalAppConfig?.testLogin || '',
		password: GlobalAppConfig?.testPassword || '',
	})

	const handleChange = ({target}) => {
		const { name, value } = target
		updateFormData({
			formData,
			name,
			value,
			setFormData,
		})
	}

	const reject = (note, answer = {}) => {
		console.debug('reject-submitting', {note}, {answer})
		writeErrors({ ...note, ...answer }, setErrors)
		setIsSending(false)
	}

	const onSubmit = async (event) => {
		event.preventDefault()
		// setIsSending(true)

		const actualErrors = validateLoginData(formData, writeErrors)

		if (haveErrors(actualErrors)) {
			reject({...actualErrors})
			return
		}

		const { status, data } = await loginUser(formData.email, formData.password)

		console.log(data?.auth_token);

		if (!status) {
			reject({common: [data?.non_field_errors || text.inputDataError]})
			return
		}

		reloadPage()
	}

	return (
		<LightFormModule {...{onSubmit}}>
			<header>
				<h1>{title}</h1>
				<ErrorMessage {...{errors}} />
			</header>
			<section>
				<Input
					label="Email"
					placeholder="example@mail.com"
					type="email"
					name="email"
					onChange={handleChange}
					value={formData?.email}
					autoComplete='email'
					errors={errors?.email}
				/>
				<Input
					label="Пароль"
					type="password"
					name="password"
					onChange={handleChange}
					value={formData?.password}
					autoComplete='on'
					errors={errors?.password}
				/>
			</section>
			<footer>
				<Btn label="Войти" disabled={isSending} />
				<Link to={RATES}>Регистрация</Link>
			</footer>
		</LightFormModule>
	)
}

export default UserLoginForm