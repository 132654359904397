import './PaginateNote.scss'

const PaginateNote = ({text}) => {
	return (
		<div className="PaginateNote">
			{text}
		</div>
	);
}

export default PaginateNote